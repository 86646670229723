import React from "react";
import { Box, Container, Grid } from "@mui/material";

import "./App.css";

import { Footer, Header, Profile } from "./components";
import { Portfolio, Resume, NotFound, Contact } from "./pages";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
	palette: {
		text: {
			secondary: "#333333", // Your custom secondary text color (dark gray)
		},
	},
});
function App() {
	return (
		<ThemeProvider theme={theme}>
			<Container sx={{ pt: 8 }}>
				<Grid container spacing={7}>
					<Grid item xs={12} sm={12} md={4}>
						<Profile />
					</Grid>
					<Grid item xs={12} sm={12} md={8}>
						<Router>
							<>
								<Header />
							</>
							<Box className='main_content container_shadow'>
								<Routes>
									<Route
										path='/portfolio'
										element={<Portfolio />}
									/>
									<Route
										path='/index.html'
										element={<Resume />}
									/>
									<Route path='/' element={<Resume />} />
									<Route path='/home' element={<Resume />} />
									<Route
										path='/contact'
										element={<Contact />}
									/>
									<Route path='*' element={<NotFound />} />
								</Routes>
							</Box>
						</Router>
					</Grid>
				</Grid>
				<Footer />
			</Container>
		</ThemeProvider>
	);
}

export default App;
