import { CardContent, Icon, Typography } from "@mui/material";

function CustomCard({ icon, title, description, styles }) {
	return (
		<>
			<CardContent className={styles}>
				<Icon>{icon}</Icon>
				<Typography
					sx={{ fontSize: 14 }}
					color='text.secondary'
					gutterBottom>
					{title}
				</Typography>
				<Typography variant='body2'>{description}</Typography>
			</CardContent>
		</>
	);
}
export default CustomCard;
