import React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { Typography } from "@mui/material";

export default function CustomTimeline({ title, icon, children }) {
	return (
		<Timeline
			sx={{
				[`& .${timelineItemClasses.root}:before`]: {
					flex: 0,
					padding: 0,
					backgroundColor: "#eee",
				},
			}}>
			{/* Item Header */}
			<TimelineItem sx={{ minHeight: "80px" }}>
				<TimelineSeparator
					sx={{
						color: "#eee",
						fontSize: "14px",
						padding: 0,
						margin: "-2px 0 2px  0",
					}}>
					<TimelineDot
						sx={{
							color: "#000",
							backgroundColor: `var(--main-color)`,
							fontSize: "small",
							padding: "10px",
							margin: 0,
							borderWidth: "5px",
						}}
						className={"timeline_dot_header"}>
						{icon}
					</TimelineDot>
					<TimelineConnector />
				</TimelineSeparator>
				<TimelineContent
					sx={{ paddingTop: "0px", marginBottom: "20px" }}>
					<Typography variant='h6' sx={{ paddingTop: "7px" }}>
						{title}
					</Typography>
				</TimelineContent>
			</TimelineItem>
			{children}
		</Timeline>
	);
}
/*52172954019910580626946*/

export function CustomTimelineSeprator() {
	return (
		<TimelineSeparator
			sx={{ paddingLeft: "18px" }}
			className='seprator_padding'>
			<TimelineDot
				variant='outlined'
				sx={{
					color: "#000",
					borderColor: `var(--main-color)`,
					padding: "2px",
				}}
			/>
			<TimelineConnector />
		</TimelineSeparator>
	);
}
