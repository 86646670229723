import React from "react";
import "./Resume.css";
import {
	Card,
	Grid,
	List,
	ListItem,
	ListItemText,
	Typography,
} from "@mui/material";

import resumeData from "../../utils/resumeData";
import { CustomTimelineSeprator } from "../../components/Timeline/Timeline";
import { CertContainer, CustomCard, CustomTimeline } from "../../components";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import SchoolIcon from "@mui/icons-material/School";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";

function AboutMe() {
	return (
		<>
			{/* about me */}
			<Grid container className='section aboutMe_container'>
				<Grid item className='section_title' xs={12}>
					<Typography variant='h6'>About Me</Typography>
				</Grid>
				<Grid item xs={12}>
					{Object.keys(resumeData.aboutMe).map((para, index) => (
						<Typography
							color='text.secondary'
							component='p'
							key={index}
							title={`para+${index}`}>
							{resumeData.aboutMe[para]}
						</Typography>
					))}
				</Grid>
			</Grid>
		</>
	);
}
function ExperienceAndEducation() {
	return (
		<Grid container className='section '>
			<Grid item className='section_title' xs={12}>
				<Typography variant='h6'> Resume</Typography>
			</Grid>
			<Grid item xs={12}>
				<Grid container className=''>
					<Grid item className='experience_section' sm={12} md={7}>
						<CustomTimeline
							title='Experience'
							icon={<WorkHistoryIcon />}>
							{resumeData.experience.map((exp, index) => (
								<TimelineItem key={index}>
									<CustomTimelineSeprator className='separator_padding'>
										<TimelineDot
											variant='outlined'
											className='timeline_dot'
										/>
										<TimelineConnector />
									</CustomTimelineSeprator>
									<TimelineContent>
										<Typography className='timeline_title'>
											{exp.title}
										</Typography>
										<Typography
											variant='caption'
											className='timeline_date'>
											{exp.date}
										</Typography>
										<Typography
											color='text.secondary'
											variant='body2'
											className='timeline_description'>
											{exp.description}
										</Typography>
									</TimelineContent>
								</TimelineItem>
							))}
						</CustomTimeline>
					</Grid>

					<Grid item className='education_section' sm={12} md={5}>
						<Grid container>
							<Grid item>
								<CustomTimeline
									title='Education'
									icon={<SchoolIcon />}>
									{resumeData.education.map((edu, index) => (
										<TimelineItem key={index}>
											<CustomTimelineSeprator className='separator_padding'>
												<TimelineDot
													variant='outlined'
													className='timeline_dot'
												/>
												<TimelineConnector />
											</CustomTimelineSeprator>
											<TimelineContent>
												<Typography className='timeline_title'>
													{edu.title}
												</Typography>
												<Typography
													variant='caption'
													className='timeline_date'>
													{edu.date}
												</Typography>
											</TimelineContent>
										</TimelineItem>
									))}
								</CustomTimeline>
							</Grid>
							<Grid item>
								<Certification />
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

function Services() {
	return (
		<Grid container className='section service_container'>
			<Grid item className='section_title' xs={12}>
				<Typography variant='h6'> My Services</Typography>
			</Grid>
			<Grid item xs={12}>
				<Grid
					container
					gap={3}
					alignItems='center'
					justifyContent='center'>
					{resumeData.services.map((service, index) => (
						<Grid
							item
							xs={12}
							sm={6}
							md={3}
							key={`service-${index}`}>
							<Card
								key={`card-${index}`}
								raised
								sx={{ minHeight: "215px" }}>
								<CustomCard
									icon={service.icon}
									title={service.title}
									description={service.description}
									styles='service_card'
								/>
							</Card>
						</Grid>
					))}
				</Grid>
			</Grid>
		</Grid>
	);
}
function Skills() {
	return (
		<Grid container className='section skills_container'>
			<Grid item className='section_title' xs={12}>
				<Typography variant='h6'> Skills</Typography>
			</Grid>
			<Grid item xs={12} padding={2}>
				<Grid container spacing={2} justify='space-between'>
					{resumeData.skills.map((skill, index) => (
						<Grid
							item
							xs={12}
							sm={6}
							md={3}
							className='skill'
							key={`skill-${index}`}>
							<Typography
								key={`skill_${index}`}
								variant='caption'
								className='skill_title'>
								{skill.title}
							</Typography>
							<List
								key={`skill-list-${index}`}
								sx={{ listStyleType: "disc" }}>
								{skill.description.map((ele, index) => (
									<ListItem
										key={`${skill.shorthand}-${index}`}
										disablePadding
										sx={{ display: "list-item" }}>
										<ListItemText>
											<Typography
												color='text.secondary'
												variant='body2'
												className='skill_description'>
												{ele}
											</Typography>
										</ListItemText>
									</ListItem>
								))}
							</List>
						</Grid>
					))}
				</Grid>
			</Grid>
		</Grid>
	);
}

function Certification() {
	/**certification: [
		{
			title: "An Introduction to Mentoring for Mentors",
			issueDate: "Issued Apr 2024 · Expires Apr 2026",
			issuerName: "Chronus",
			icon:  */
	return (
		<Grid>
			<Grid item xs={12}>
				<CustomTimeline
					title='My Certifications'
					icon={<WorkspacePremiumIcon />}
				/>
			</Grid>
			<Grid item>
				{resumeData.certification.map((certification, index) => (
					<CertContainer key={index} {...certification} /> // Spread certification props
				))}
			</Grid>
		</Grid>
	);
}
function Resume() {
	return (
		<>
			<AboutMe />
			{/*education and experience*/}

			<ExperienceAndEducation />
			{/* services */}
			<Services />

			{/**skills */}
			<Skills />
		</>
	);
}

export default Resume;
