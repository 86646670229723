import React from "react";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import "./Button.css";

function CustomButton({
	text,
	icon,
	type = "button",
	variant = "contained",
	onClick,
}) {
	const theme = useTheme();

	const styles = {
		root: {
			color: "#000", //theme.palette.text.primary,
			backgroundColor: `var(--main-color)`, //theme.palette.primary.main,
			padding: theme.spacing(1, 2.5),
			borderRadius: "50px",
			transition: "all 0.2s ease-in-out", // Add transition for smooth hover effect
			"&:hover": {
				// Define hover styles
				color: `var(--main-color)`, // Invert text color using contrastText
				backgroundColor: "#000", // Invert background color using a function
			},
		},
		contained: {
			backgroundColor: `var(--main-color)`, // Base color for contained buttons
		},
		outlined: {
			border: `1px solid var(--main-color)`, // Outline for outlined buttons
			backgroundColor: "transparent", // Transparent background for outlined buttons
		},
	};

	return (
		<Button
			type={type}
			className='custom_btn' // Existing class
			variant={variant}
			onClick={onClick}
			sx={{
				...styles.root,
				...(variant === "contained"
					? styles.contained
					: variant === "outlined"
					? styles.outlined
					: ""),
			}} // Apply styles conditionally
			endIcon={icon && <div className='btn_icon_container'>{icon}</div>}>
			<span className='btn_text'>{text}</span>
		</Button>
	);
}

export default CustomButton;
