import React, { useState } from "react";
import { TextField, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";
import SuccessModal from "../../utils/SuccessModal/SuccessModal";
import { CustomButton } from "../../components";
import EmailIcon from "@mui/icons-material/Email";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import styles from "./Contact.css";

const Contact = () => {
	const [modalOpen, setModalOpen] = React.useState(false);
	const [name, setName] = useState("");

	const [email, setEmail] = useState("");

	const [message, setMessage] = useState("");

	function handleSubmit(event) {
		event.preventDefault();
		console.log(name, email, message);

		const data = {
			name: name,
			email: email,
			message: message,
		};
		const serviceId = "service_4mn200j"; // Replace with your EmailJS service ID
		const templateId = "template_hu6abpc"; // Replace with your EmailJS template ID
		const publicKey = "UjmcbvV2NFOFZNvPO"; // Replace with your EmailJS public key

		emailjs
			.send(serviceId, templateId, data, publicKey)
			.then((response) => {
				console.log("SUCCESS!", response.status, response.text);
				//reset();
				setModalOpen(true);
				//alert("Your message has been sent!"); // Show success message
			})
			.catch((err) => {
				console.error("FAILED...", err);
				alert(
					"There was an error sending your message. Please try again."
				);
			});
	}
	const handleReset = () => {
		setName("");
		setEmail("");
		setMessage("");
	};
	const inputStyles = {
		marginBottom: 4,
		color: "#000",
		fontFamily: "Arial",
		fontWeight: "bold",
		"&.MuiOutlinedInput-notchedOutline": {
			borderColor: "##595454",
			borderWidth: "2px",
		},
		"&.MuiFocused": {
			"& .MuiOutlinedInput-notchedOutline": {
				borderColor: "var(--main-color)",
				borderWidth: "3px",
			},
		},
		"&:hover:not(.MuiFocused)": {
			"& .MuiOutlinedInput-notchedOutline": {
				borderColor: "var(--main-color)",
			},
		},
	};

	const labelStyles = {
		color: "var(--main-color)",
		fontWeight: "bold",
		"&.MuiFocused": {
			color: "#000",
			fontWeight: "bold",
		},
	};
	return (
		<React.Fragment>
			<h2>Contact</h2>
			<form onSubmit={handleSubmit} action={<Link to='/' />}>
				<TextField
					type='text'
					variant='outlined'
					color='secondary'
					label='Name'
					onChange={(e) => setName(e.target.value)}
					value={name}
					fullWidth
					required
					className={styles.focusStyles}
					sx={{ ...inputStyles }}
					InputLabelProps={{ style: { ...labelStyles } }}
					tabIndex={0}
				/>

				<TextField
					type='email'
					variant='outlined'
					color='secondary'
					label='Email'
					onChange={(e) => setEmail(e.target.value)}
					value={email}
					fullWidth
					required
					className={styles.focusStyles}
					sx={{ ...inputStyles }}
					InputLabelProps={{ style: { ...labelStyles } }}
					tabIndex={0}
				/>
				<TextField
					type='text'
					variant='outlined'
					color='secondary'
					label='Your message'
					onChange={(e) => setMessage(e.target.value)}
					value={message}
					multiline
					rows={4}
					required
					fullWidth
					className={styles.focusStyles}
					sx={{ ...inputStyles }}
					InputLabelProps={{ style: { ...labelStyles } }}
					tabIndex={0}
				/>
				<Stack spacing={2} direction='row' sx={{ marginBottom: 4 }}>
					<CustomButton
						type='submit'
						text={"Send"}
						icon={<EmailIcon />}
						tabIndex={0}
					/>

					<CustomButton
						type='Reset'
						text={"Reset"}
						variant='outlined'
						icon={<RestartAltIcon />}
						onClick={handleReset}
						tabIndex={0}
					/>
				</Stack>
			</form>
			<SuccessModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
		</React.Fragment>
	);
};

export default Contact;
