import React from "react";
import Modal from "react-modal";
import "./SuccessModal.css";
import { setAppElement } from "react-modal";

import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close"; // Import CloseIcon

export default function SuccessModal({ modalOpen, setModalOpen }) {
	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			backgroundColor: "var(--main-color)",
		},
	};

	// Find your root app element (assuming ID is 'app-root')
	const appElement = document.getElementById("root");

	// Set the app element before rendering the modal (ideally in a parent component)
	setAppElement(appElement);

	const handleCloseModal = () => {
		setModalOpen(false); // Close the modal on button or Esc key press
	};

	// Handle Esc key press for closing modal (optional)
	const handleKeyDown = (event) => {
		if (event.key === "Escape") {
			handleCloseModal();
		}
	};

	React.useEffect(() => {
		// Add event listener for Esc key press on modal open
		document.addEventListener("keydown", handleKeyDown);

		// Cleanup function to remove event listener on modal close
		return () => document.removeEventListener("keydown", handleKeyDown);
	}, [modalOpen]); // Re-run effect only when modalOpen changes

	return (
		<Modal isOpen={modalOpen} style={customStyles}>
			<div className='modal-inner'>
				{/* Close button using CloseIcon */}
				<button className='close-button' onClick={handleCloseModal}>
					<CloseIcon />
				</button>

				<label htmlFor='' className='message'>
					Email Send Successfully
				</label>
				<img
					className='added-image'
					src='https://cdn.dribbble.com/users/1238709/screenshots/4069900/success_celebration_800x600.gif'
					alt='Success Celebration'
				/>
				<Link to='/' className='take-home-button'>
					OK
				</Link>
			</div>
		</Modal>
	);
}
