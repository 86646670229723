import React from "react";
import "./Footer.css";
import { Grid, Link, Typography } from "@mui/material";
import resumeData from "../../utils/resumeData";

function Footer() {
	return (
		<footer>
			<Grid container spacing={2} padding={1}>
				<Grid item xs={12} sm={8} md={8} flexGrow={1}>
					<Typography variant='h4' gutterBottom>
						{resumeData.name}
					</Typography>
				</Grid>
				<Grid item xs={12} sm={4} md={4}>
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<Typography variant='body2' component='span'>
								Designed and Developed by {resumeData.name}.
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant='body2' component='span'>
								Inspiration from
								<Link
									style={{ paddingLeft: "10px" }}
									href='https://themeforest.net/user/tavonline'
									target='_blank'
									underline='none'>
									tavonline
								</Link>
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</footer>
	);
}

export default Footer;
