import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";

/**MUI components */
import {
	AppBar,
	Box,
	IconButton,
	Menu,
	MenuItem,
	MenuList,
	Toolbar,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import MenuIcon from "@mui/icons-material/Menu";
import SendIcon from "@mui/icons-material/Send";

/**Custom component and styles */
import CustomButton from "../Button/Button";
import "./Header.css";

/**Navbar links  */
const pages = ["Home", "Portfolio", "Contact"];

const Header = () => {
	const [anchorNav, setAnchorNav] = useState(false);

	const openMenu = (event) => {
		setAnchorNav(event.currentTarget);
	};
	const closeMenu = () => setAnchorNav(false);

	// Get current pathname from react-router-dom
	return (
		<AppBar position='static' color='inherit' className='navBar'>
			<Toolbar sx={{ pl: 0 }}>
				<IconButton
					size='large'
					edge='start'
					color='#000'
					aria-label='Home'
					component={Link}
					to='/'
					className='home-btn'
					sx={{
						"&:focus, &:hover": {
							outline: `2px solid var(--secondary-color)`,
						},
						display: { xs: "none", md: "flex" },
					}}
					tabIndex={0}>
					<HomeIcon />
				</IconButton>
				<Box
					className='nav-links'
					sx={{
						display: { xs: "none", md: "flex", flexGrow: 1 },
					}}>
					{pages.map((page, index) => (
						<NavLink
							key={index}
							to={`/${page.toLowerCase()}`}
							sx={{
								"&:focus, &:hover": {
									outline: `2px solid var(--secondary-color)`,
								},
							}}
							tabIndex={0}>
							{page}
						</NavLink>
					))}
				</Box>
				<Box
					className='header_button'
					sx={{ display: { xs: "none", md: "flex" } }}>
					<CustomButton
						text={"Hire Me"}
						icon={<SendIcon />}
						onClick={() => {
							window.location.href = `mailto:gurpreet.devwork@gmail.com?subject=Contact for Website&body=Replace this with your message`;
						}}
					/>
				</Box>
				<Box sx={{ display: { xs: "flex", md: "none" } }}>
					<IconButton
						size='2rem'
						edge='start'
						color='#000'
						aria-label='Menu'
						sx={{ display: { xs: "flex", md: "none" } }}
						onClick={openMenu}
						tabIndex={0}>
						<MenuIcon
							fontSize='large'
							style={{ padding: "20px" }}
						/>
					</IconButton>
					<Menu
						onClose={closeMenu}
						open={!!anchorNav}
						anchorEl={anchorNav}
						sx={{ display: { xs: "flex", md: "none" } }}>
						<MenuList>
							{pages.map((page, index) => (
								<MenuItem
									href={`/${page.toLowerCase()}`}
									component='a'
									key={index}>
									{page}
								</MenuItem>
							))}
						</MenuList>
					</Menu>
				</Box>
			</Toolbar>
		</AppBar>
	);
};

export default Header;
