import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import QuizIcon from "@mui/icons-material/Quiz";
const certLogos = {
	microsoft: require("../assets/images/cert_logo/microsoft_logo.jpeg"),
	accenture: require("../assets/images/cert_logo/accenture_logo.jpeg"),
	chronus: require("../assets/images/cert_logo/chronussoftware_logo.jpeg"),
	linkedin: require("../assets/images/cert_logo/linkedin_logo.jpeg"),
	pegasystems: require("../assets/images/cert_logo/pegasystems_logo.jpeg"),
};

const gurpreetKaurData = {
	name: "Gurpreet Kaur",
	title: "FrontEnd Developer",
	email: "gurpreet.devwork@gmail.com",
	address: "Melbourne",
	socials: {
		LinkedIn: {
			links: "https://www.linkedin.com/in/gurpreetdevwork/",
			text: "GurpreetDevWork",
			icon: "icon",
		} /*
		GitHub: {
			links: "http://www.gitHub.com",
			text: "gitHub",
			icon: "icon",
		},*/,
	},
	aboutMe: {
		para1: "I'm Gurpreet Kaur, a passionate frontend developer based out in Melbourne who thrives on turning ideas into visually stunning and interactive experiences. With a knack for problem-solving and a sprinkle of creative flair, I bridge the gap between design and functionality.",
		para2: "I'm fueled by learning new technologies and pushing boundaries to create innovative web experiences. When I'm not coding, you might find me in my garden.",
	},
	experience: [
		{
			title: "TechLead, HCL Technologies ",
			date: "Nov 2021 - May 2024",
			description:
				"Coles Rover is an interactive self-service platform offering diverse options for shoppers, enabling seamless order placement through various devices such as desktops, laptops, tablets (Microsoft OS), and Android mobile devices.",
		},
		{
			title: "Frontend Developer, TitanFX ",
			date: "Oct 2019 - Nov 2021",
			description:
				"I Worked on creating component-based architecture, Creating fully functional components for error handling and email templating using localization and accessibility guidelines. ",
		},
		{
			title: "Frontend Developer, Precredits/ Savebo ",
			date: "Oct 2019 - Jan 2020",
			description:
				"Led the development of a phase 1 e-commerce website from scratch (greenfield project). Established the application's foundation by building the initial UI skeleton and crafting a comprehensive style guide. Championed reusability by creating well-defined, modular components for seamless integration across the application. Integrated a REST API built with Laravel to facilitate data exchange with the backend.",
		},
		{
			title: "Principal Technical Solutions Engineer/Sr Frontend Developer, Pega Systems ",
			date: "July 2016 - Sep 2019",
			description:
				"Created UI Components. Helped customers to utilize PRPC7 in a standardized format. Worked as SPOC for Localization and Accessability. Led team of Grads and junior developers. Conducted brown bag sessions on debugging and effective communication",
		},
		{
			title: "Application Designer/UI Lead, Accenture",
			date: "Mar 2014 - July 2016",
			description:
				"Migrated Legacy application to AngularJS. Led the migration from the start, provided prototypes, got approvals for final implementation, and expanded further on the same. Created responsive SPA design and tested across web, tablet and mobile platforms.",
		},
		{
			title: "Web Developer, HCL Tech Ltd",
			date: "July 2012 - March 2014",
			description:
				"Revamped Maple site to PHP and added accessibility to same. Worked as a fullstack developer to complete the migration",
		},
		{
			title: "Software Engineer, Mindtree Ltd.",
			date: "Dec 2011 – June 2012",
			description:
				"Maintained exisiting Project space, A project similar to Jira, where you can create Projects, add team memebers, assign task and track progress.",
		},
		{
			title: "Software Engineer, Sourcebits Technologies Pvt. Ltd",
			date: "Sep 2009 - Dec 2011",
			description:
				"Worked on different web applications based on Drupal, Joomla and osCommerce. Integrating social media components for marketing and created user forum and blod component.",
		},
	],
	education: [
		{
			title: "MCA(Master of Computer Application)",
			date: "2005-2008",
		},
		{
			title: "B.Com(Bachelor of Commerrce)",
			date: "2003-2005",
		},
	],
	services: [
		{
			title: "Web-app Developer",
			description:
				"Several years of personal and professional experience with web technologies.",
			icon: <DeveloperModeIcon />,
		},
		{
			title: "A11Y Expert",
			description: "Web accessibility is within my skillset.",
			icon: <AccessibilityIcon />,
		},
		{
			title: "Challenge Me!",
			description: "Are there any complex tasks I can assist you with?",
			icon: <QuizIcon />,
		},
	],
	skills: [
		{
			title: "FRONT-END",
			shorthand: "FE",
			description: [
				"React",
				"JavaScript",
				"AngularJS",
				"Bootstrap",
				"Material UI",
				"SASS",
			],
		},
		{
			title: "BACKEND",
			shorthand: "BE",
			description: ["NodeJS", "PHP", ".NET"],
		},
		{
			title: "DATABASES",
			shorthand: "DB",
			description: ["MySQL", "Postgres"],
		},
		{
			title: "SOURCE CONTROL",
			shorthand: "SC",
			description: ["Git", "GitHub", "SCRUM/Agile"],
		},
	],
	certification: [
		{
			title: "An Introduction to Mentoring for Mentors",
			issueDate: "Issued Apr 2024 · Expires Apr 2026",
			issuerName: "Chronus",
			icon: certLogos.chronus,
		},
		{
			title: "Learning React.js",
			issueDate: "Issued Jan 2020",
			issuerName: "LinkedIn",
			icon: certLogos.linkedin,
		},
		{
			title: "React.js Essential Training",
			issueDate: "Issued Jan 2020",
			issuerName: "LinkedIn",
			icon: certLogos.linkedin,
		},
		{
			title: "Certified UI Specialist",
			issueDate: "Issued Nov 2018",
			issuerName: "Pegasystems",
			icon: certLogos.pegasystems,
		},
		{
			title: "Certified System Architect",
			issueDate: "Issued Aug 2016",
			issuerName: "Pegasystems",
			icon: certLogos.pegasystems,
		},
		{
			title: "Agile Foundation",
			issueDate: "Issued Jan 2016",
			issuerName: "Accenture",
			icon: certLogos.accenture,
		},
		{
			title: "Microsoft Certified Professional",
			issueDate: "Issued Sep 2015",
			issuerName: "Microsoft",
			icon: certLogos.microsoft,
		},
		{
			title: "Specialist: Programming in HTML5 with JavaScript and CSS3",
			issueDate: "Issued Sep 2015",
			issuerName: "Microsoft",
			icon: certLogos.microsoft,
		},
	],
};
export default gurpreetKaurData;
