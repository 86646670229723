import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import "./Profile.css";
import { CustomButton, CustomTimeline } from "../../components";
import resumeData from "../../utils/resumeData";
import FaceIcon from "@mui/icons-material/Face";
import TimelineItem from "@mui/lab/TimelineItem";
import { CustomTimelineSeprator } from "../Timeline/Timeline";
import TimelineContent from "@mui/lab/TimelineContent";
import GetApp from "@mui/icons-material/GetApp";
import logoImage from "../../assets/images/logo.png";
import { saveAs } from "file-saver"; // Import the saveAs function
import resume from "../../assets/Gurpreet_Kaur.pdf";

function CustomTimelineItem({ title, text, link }) {
	return (
		<TimelineItem className='timelineItem'>
			<CustomTimelineSeprator />
			<TimelineContent className='timeline_content'>
				{link ? (
					<Typography className='timelineItem_text'>
						<span>{title}:</span>
						<a
							href={link}
							target='_blank'
							rel='noreferrer noopener'>
							<span
								color='text.secondary'
								className='timelineItem_value'>
								{text}
							</span>
						</a>
					</Typography>
				) : (
					<Typography className='timelineItem_text'>
						<span>{title}:</span>
						<span
							color='text.secondary'
							className='timelineItem_value'>
							{text}
						</span>
					</Typography>
				)}
			</TimelineContent>
		</TimelineItem>
	);
}
function Profile() {
	const backgroundImageStyle = {
		backgroundImage: `url(${logoImage})`,
		backgroundRepeat: "no-repeat", // Prevent repeating the image
		backgroundPosition: "center", // Center the image
		backgroundSize: "cover", // Stretch to fill the container (adjust as needed)
		width: "80px", // Set the width of the container
		height: "80px", // Set the height of the container
	};
	const downloadCV = () => {
		saveAs(resume, "Gurpreet_Kaur.pdf");
	};

	return (
		<Box className='profile container_shadow'>
			<Box className='profile_name' display='flex'>
				<Box style={backgroundImageStyle} />
				<Stack alignItems='center' justifyContent='center'>
					<Typography className='name'> {resumeData.name}</Typography>
					<Typography className='title'>
						{resumeData.title}
					</Typography>
					<Typography variant='body2'>
						{resumeData.address}
					</Typography>
				</Stack>
			</Box>
			<figure className='profile_image'>
				<img
					src={require("../../assets/images/profile.jpeg")}
					alt='Gurpreet Kaur'
				/>
			</figure>
			<div className='profile_information'>
				<CustomTimeline icon={<FaceIcon />}>
					<CustomTimelineItem title='Name' text={resumeData.name} />
					<CustomTimelineItem title='Title' text={resumeData.title} />
					<CustomTimelineItem title='Email' text={resumeData.email} />
					{Object.keys(resumeData.socials).map(
						(socialData, index) => (
							<CustomTimelineItem
								key={`socials_${index}`}
								title={socialData}
								text={resumeData.socials[socialData].text}
								link={resumeData.socials[socialData].links}
							/>
						)
					)}
				</CustomTimeline>
				<div className='btn-container'>
					<CustomButton
						text={"Download CV"}
						icon={<GetApp />}
						onClick={downloadCV}
					/>
				</div>
			</div>
		</Box>
	);
}
export default Profile;
