import { Box, Typography } from "@mui/material";

const CertContainer = ({ title, issueDate, issuerName, icon }) => {
	//const theme = useTheme(); // Access theme for responsive styling (optional)

	return (
		<Box
			p={1}
			m={2}
			sx={{
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
				border: `1px dotted var(--main-color)`,
			}}>
			<Box sx={{ display: "flex", flexDirection: "column" }}>
				<Typography className='timeline_title'>{title}</Typography>
				<Typography variant='caption'>
					Issued Date: {issueDate}
				</Typography>
				<Typography variant='body2' className='timeline_description'>
					Issuer: {issuerName}
				</Typography>
			</Box>
			<img
				src={icon}
				alt='Certification Logo'
				style={{
					width: "48px",
					boxShadow: `5px 5px 5px var(--secondary-color)`,
				}}
			/>
		</Box>
	);
};
export default CertContainer;
